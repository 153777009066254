.articles {
    &--main {
        padding: 30px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        background: var(--color-grey);
    }

    &__title {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-bottom: 30px;

        h1, .head__title {
            margin: 0;
            padding: 0;

            font-weight: 300;
            font-size: 24px;
            line-height: 1;
            color: var(--color-black);
            border-bottom: 2px solid var(--color-gold);

            a {
                font-weight: 300;
                font-size: 24px;
                line-height: 1;
                color: var(--color-black);
            }
        }
    }

    &__slides {
    }

    .slick-track {
        display: flex;
        justify-content: center;
        align-items: stretch;
        height: 100%;
    }

    .slick-track .slick-slide {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: stretch;
    }

    &__card {
        display: flex;
        height: auto;
        width: auto;
        max-width: 400px;
        justify-content: center;
        align-items: stretch;

        &__image {
            display: flex;

            a {
                display: block;
                text-decoration: none;
                color: var(--color-black);

                &:hover {
                    text-decoration: underline;

                    img {
                        filter: grayscale(0%);
                    }
                }
            }

            img {
                object-fit: cover;
                filter: grayscale(50%);
            }
        }

        &__name {
            display: flex;
            background: var(--color-white);
            padding: 20px;
            color: var(--color-black);
            font-size: 14px;
            line-height: 22px;
            text-transform: uppercase;
            max-height: 100%;
            height: 100%;
        }
    }
}


.article__slides-dots {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    margin: 15px 0 0;

    .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        li {
            text-align: center;
            margin-left: 17px;
            margin-right: 17px;
        }

        button {
            display: inline-block;
            padding: 0;
            width: 13px;
            height: 13px;
            background-color: var(--color-red);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
    }

    .slick-active {
        button {
            width: 20px;
            height: 20px;
            background-color: var(--color-red);
        }
    }
}
