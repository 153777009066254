.calculations{
    &__wrapper {
        margin-top: 0;
        display: block;
        width: 100%;
        .pages__title{
            margin-bottom: 0;
            border: none;
            width: auto;
            text-align: left;
            h1 {
                font-style: normal;
                font-weight: 300;
                font-size: 24px;
                line-height: 28px;
                text-transform: uppercase;
                color: var(--color-black);
                border: none;
                text-align: left;
            }
        }
    }
    $__content{
        display: block;
        width: 100%;
    }
}
