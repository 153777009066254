/* Page */

.page,
.page__body {
    height: 100%;
}

.page {
    color: var(--color-black);
    font-family: 'Circe', sans-serif;
    background-color: var(--color-white);
    line-height: 1;
}

.page__body {
    margin: 0 auto;
}

.hidden {
    display: none !important;
}
