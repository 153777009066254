.partners {
    position: relative;
    background: var(--color-white);
    padding: 0;
    margin: 0;

    &__slides {
        position: relative;
    }

    &__card {
        position: relative;
        max-height: 736px;
        background: var(--color-black-light);

        &__image {
            position: relative;
            max-width: 100%;
            height: auto;
            overflow: hidden;
            img {
                max-width: 100%;
                height: auto;
                margin: 0 auto;
                width: 100%;
            }
        }

        &__body-wrap {
            position: absolute;
            height: auto;
            width: auto;
            bottom: 60px;
            left: 0;
            display: block;
            color: var(--color-white);
            padding: 15px;
        }

        &__title {
            position: relative;
            display: block;
            max-width: 300px;
            h2, .text {

                font-weight: 300;
                font-size: 30px;
                line-height: 1;
                text-transform: uppercase;
                color: var(--color-white);
                margin: 0;
                padding: 0;
            }


        }

        &__line {
            display: block;
            height: 2px;
            width: 100%;
            background-color: var(--color-red);
            margin-top: 15px;
            margin-bottom: 15px;

        }

        &__description {
            text-transform: uppercase;
            max-width: 550px;

            p {
                text-transform: uppercase;
                margin: 0;
                padding: 0;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: var(--color-white);
            }
        }

        &__link {
            display: block;
            height: auto;
            width: auto;
            position: relative;
            margin-top: 40px;
        }
    }

}
