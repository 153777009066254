.container__slider {
    position: relative;
    //min-height: 100vh;
    overflow: hidden;
}

.slider-top {
    position: relative;

    &__img-wrap {
        position: relative;
        width: 100%;
        overflow: hidden;
        img {
            overflow: hidden;
            margin: 0 auto;
            width: 100%;
        }
    }

    &__logo {
        position: absolute;
        left: 30px;
        top: 30px;
        z-index: 100;
        &_svg {
            width: 88px;
            height: 30px;

            path {
                fill: var(--color-white);
            }
        }
    }

    &__body-wrap {
        position: absolute;
        height: auto;
        width: auto;
        bottom: 80px;
        left: 0px;
        padding-left: 30px;
        padding-right: 30px;
        display: block;
    }

    &__line {
        display: block;
        height: 2px;
        width: auto;
        background-color: var(--color-red);
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &__title {
        position: relative;
        display: block;

        h2, .text {

            font-weight: 300;
            font-size: 30px;
            line-height: 1;
            text-transform: uppercase;
            color: var(--color-white);
            margin: 0;
            padding: 0;
        }
    }

    &__description {
        position: relative;
        display: block;
        max-width: 100%;

        p {
            margin: 0;
            padding: 0;

            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: var(--color-white);
        }
    }

    &__link {
        display: block;
        height: auto;
        width: auto;
        position: relative;
        margin-top: 30px;
    }
}


.slider-top-dots {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    margin: 0;
    list-style: none;

    .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: baseline;

        li {
            text-align: center;
            margin: 0 10px;
        }

        button {
            display: inline-block;
            padding: 0;
            width: 13px;
            height: 13px;
            background-color: var(--color-red);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
    }

    .slick-active {
        button {
            width: 20px;
            height: 20px;
            background-color: var(--color-red);
        }
    }
}
