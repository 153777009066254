
.product {
    &__wrapper {
        width: 100%;
    }

    &__slider {
        width: 100%;
        position: relative;

        picture {
            position: relative;
            display: flex;
            justify-content: center;
            align-content: center;
        }

    }

    &__content {
        padding: 50px 30px;
        &__title {
            position: relative;

            h1 {
                margin: 0;
                padding: 0;
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                line-height: 36px;
            }
        }

        &__line {
            position: relative;
            max-width: 300px;
            height: 3px;
            background: var(--color-black-light-2);
            margin-top: 15px;
            margin-bottom: 20px;
        }

        &__vendor {

            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 18px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            color: rgba(0, 0, 0, 0.5);
        }

        &__body {
            padding-bottom: 0;
            width: 100%;
            margin: 0 0 30px;

            h2, p, a {
                padding: 0;
                color: var(--color-black);
                font-size: 18px;
                line-height: 26px;
                margin: 0 0 20px;
            }

            a {
                text-decoration: underline;
            }

            h2 {
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        &__shops {
            &__title {
                text-align: center;

                h2 {
                    margin: 0;
                    padding: 0;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 38px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: var(--color-black);
                    justify-content: center;
                }
            }

            &__line {
                width: 230px;
                border: 1px solid #AE8567;
                margin: 0 auto 20px;
            }

            &__item {
                margin-bottom: 10px;
            }
        }
    }

    &__links {
        a {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 1;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--color-gold);
            border: 1px solid var(--color-gold);
            width: 192px;
            height: 40px;
            margin-top: 20px;
            justify-content: center;

            &:hover {
                color: var(--color-white);
                background: var(--color-gold);

            }
        }

    }

    &__bottom {
        &__wrap {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-content: center;
            align-items: center;
        }

    }
}


.slider-product-dots {
    position: absolute;
    bottom: 30px;
    width: 100%;

    .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        li {
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;
        }

        button {
            display: inline-block;
            padding: 0;
            width: 14px;
            height: 14px;
            background-color: var(--color-red);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
    }

    .slick-active {
        button {
            width: 20px;
            height: 20px;
            background-color: var(--color-red);
        }
    }
}

.pages__product {
    &__menu {
        padding: 70px 100px;
        position: relative;


        &__nav {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            justify-content: space-around;
            width: 100%;
            height: 75px;
            border-bottom: 1px solid var(--color-black);

            &__element {
                margin: 0;
                padding: 0;
                width: 25%;
                height: 75px;
                display: flex;
                justify-content: space-evenly;
                align-content: center;
                align-items: stretch;

                a {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    text-transform: uppercase;
                    color: var(--color-black);
                    border-bottom: 10px solid transparent;
                    width: 100%;
                    justify-content: center;

                    &.active, &:hover {
                        font-size: 18px;
                        font-weight: bold;
                    }

                    &.active {
                        border-bottom: 10px solid var(--color-black);
                    }
                }
            }
        }
    }

}

.pages__product__tab {
    padding: 30px 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &__nav {
        position: relative;
        display: flex;
        padding: 0 100px;
        justify-content: flex-end;
    }

    &.products {
        justify-content: space-between;
    }

    .products__card {
        flex: 0 0 calc(25% - 10px);
        margin-bottom: 50px;
    }

    &__property__list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 5px;
            color: rgba(0, 0, 0, 0.6);
            font-size: 18px;
            line-height: 28px;

            span {
                font-weight: bold;
            }
        }
    }

    &__docs {
        list-style: none;
        display: block;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        &__element {
            list-style: none;
            display: block;
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            &:last-child{
                border: none;
            }
            a {
                padding-top: 15px;
                padding-bottom: 15px;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 28px;
                display: flex;
                align-items: center;
                color: var(--color-black);
                &:hover {
                    font-weight: bold;
                }
            }
        }
    }
}

.pages__product__tab.products{
    .products__card{
        margin: 0;
    }
    .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        li {
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;
        }

        button {
            display: inline-block;
            padding: 0;
            width: 14px;
            height: 14px;
            background-color: var(--color-black);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
    }

    .slick-active {
        button {
            width: 20px;
            height: 20px;
            background-color: var(--color-black);
        }
    }
}
