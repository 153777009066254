.pages {
    &__title {
        border-bottom: 2px solid var(--color-red);
        width: 210px;
        text-align: center;

        h1 {
            text-align: center;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            color: var(--color-red);
        }
    }

    &__container {
        position: relative;
        padding: 50px 15px 30px;

    }

    &__left-block {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding: 0 10px 50px;

        .pages__title {
            margin-bottom: 30px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            width: 100%;

            li {
                margin: 0;
                padding: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                .top__list__block{
                    display: none;
                    margin-top: 0px;
                    margin-left: 10px;
                    animation: pulse 1s;
                    a{
                        font-weight: normal !important;
                    }
                    li{
                        &:last-child{
                            border: none;
                        }
                    }
                    li.active a{
                        font-weight: bold !important;
                    }
                    &.active{
                        border-top: 1px solid rgba(0, 0, 0, 0.2);
                        display: block;
                        animation: fadeIn 1s;
                    }
                }
                &:last-of-type {
                    border-bottom: 1px solid transparent;
                }

                &:hover, &.active {
                    a,button {
                        font-weight: bold;
                    }

                }

                a, button {
                    border: none;
                    background: transparent;
                    padding: 20px 0;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    justify-content: center;
                    color: var(--color-black);
                    width: 100%;

                }
            }

            &.bottom__list {
                margin-top: 30px;
                margin-bottom: 20px;
                padding-left: 15px;
                padding-right: 15px;
                li {
                    border: none;
                }

                .title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 24px;
                    color: var(--color-gold);
                    text-transform: uppercase;
                    border-left: 4px solid var(--color-gold);
                    padding-top: 15px;
                    margin-bottom: 30px;
                    padding-left: 15px;
                    margin-left: -15px;
                    padding-bottom: 5px;

                }

                .btn__list__nav {
                    padding: 15px 0;
                    text-align: left;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: var(--color-black);
                    width: 100%;
                    border-bottom: 2px solid var(--color-black);
                    &.active {
                        font-weight: bold;
                        border-bottom: 2px solid var(--color-black);
                        margin-bottom: 10px;
                        margin-top: 30px;
                    }

                }

                .list__block {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    margin-bottom: 10px;
                    animation: fadeIn 0.2s;
                    &:last-of-type{
                        border-bottom: none;
                    }
                    .list__block__menu {
                        margin: 0;
                        padding: 0;
                        height: 0;
                        display: flex;
                        opacity: 0;
                        visibility: hidden;

                        li {
                            margin: 0;
                            padding: 0;
                            width: 0;


                        }
                    }


                    &.active {
                        animation: fadeIn 0.5s;
                        border-bottom: none;
                        margin-bottom: 0;

                        .list__block__menu {
                            animation: fadeIn 1s;
                            height: auto;
                            opacity: 1;
                            visibility: visible;
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            align-content: center;
                            justify-content: center;
                            align-items: center;

                            li {
                                width: 100%;
                                margin-bottom: 10px;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                display: block;

                                a {
                                    display: block;
                                    padding: 15px 0;
                                    font-weight: normal;
                                    font-size: 18px;
                                    line-height: 1;
                                    align-items: center;
                                    text-transform: uppercase;
                                    width: 100%;
                                    color: var(--color-black);
                                }

                                &:hover, &.active {
                                    a {
                                        font-weight: bold;
                                    }
                                }

                                &.active {
                                    animation: pulse 0.2s;
                                    border-bottom: 2px solid var(--color-black);

                                }
                            }
                        }
                    }


                    &:hover {
                        .btn__list__nav {
                            font-weight: bold;
                        }
                    }
                }

                .list__element {
                    margin-bottom: 10px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    display: block;

                    &:hover, &.active {
                        a {
                            font-weight: bold;
                        }
                    }

                    &.active {
                        animation: pulse 0.2s;
                        border-bottom: 2px solid var(--color-black);

                    }


                    a {
                        display: block;
                        padding: 15px 0;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 1;
                        align-items: center;
                        text-transform: uppercase;
                        width: 100%;
                        color: var(--color-black);
                    }
                }
            }
        }

        h1 {

            font-weight: bold;
            font-size: 32px;
            line-height: 1.1;
            text-transform: uppercase;
        }
    }

    &__right-block {
        width: 100%;
        margin-top: 50px;

        .pages__title {
            border-bottom: 2px solid var(--color-gold);
            padding-bottom: 20px;
            margin: 0 auto;
            min-width: 210px;
            max-width: 100%;
            width: auto;
            text-align: center;
            margin-bottom: 30px;
        }

        h1 {

            font-weight: bold;
            font-size: 24px;
            line-height: 1.1;
            text-transform: uppercase;
            color: #000000;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            width: 100%;

            li {
                margin: 0;
                padding: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);

                &:last-of-type {
                    border-bottom: 1px solid transparent;
                }
                &:hover, &.active {
                    a {
                        font-weight: bold;
                    }
                }
                a {
                    padding: 20px 0;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    justify-content: center;
                    color: var(--color-black);
                    width: 100%;

                }
            }

            &.bottom__list {
                margin-top: 50px;
                margin-bottom: 70px;
                padding-left: 15px;
                padding-right: 15px;
                li {
                    border: none;
                }

                .title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 28px;
                    color: var(--color-gold);
                    text-transform: uppercase;
                    border-left: 4px solid var(--color-gold);
                    padding-top: 15px;
                    margin-bottom: 30px;
                    padding-left: 15px;
                    margin-left: -15px;
                    padding-bottom: 5px;

                }

                .btn__list__nav {
                    padding: 15px 0;
                    text-align: left;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: var(--color-black);
                    width: 100%;
                    border-bottom: 2px solid var(--color-black);
                    &.active {
                        font-weight: bold;
                        border-bottom: 2px solid var(--color-black);
                        margin-bottom: 10px;
                        margin-top: 30px;
                    }

                }

                .list__block {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    margin-bottom: 10px;
                    animation: fadeIn 0.2s;
                    &:last-of-type{
                        border-bottom: none;
                    }
                    .list__block__menu {
                        margin: 0;
                        padding: 0;
                        height: 0;
                        display: flex;
                        opacity: 0;
                        visibility: hidden;

                        li {
                            margin: 0;
                            padding: 0;
                            width: 0;


                        }
                    }


                    &.active {
                        animation: fadeIn 0.5s;
                        border-bottom: none;
                        margin-bottom: 0;

                        .list__block__menu {
                            animation: fadeIn 1s;
                            height: auto;
                            opacity: 1;
                            visibility: visible;
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            align-content: center;
                            justify-content: center;
                            align-items: center;

                            li {
                                width: 100%;
                                margin-bottom: 10px;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                                display: block;

                                a {
                                    display: block;
                                    padding: 15px 0;
                                    font-weight: normal;
                                    font-size: 18px;
                                    line-height: 1;
                                    align-items: center;
                                    text-transform: uppercase;
                                    width: 100%;
                                    color: var(--color-black);
                                }

                                &:hover, &.active {
                                    a {
                                        font-weight: bold;
                                    }
                                }

                                &.active {
                                    animation: pulse 0.2s;
                                border-bottom: 2px solid var(--color-black);

                                }
                            }
                        }
                    }


                    &:hover {
                        .btn__list__nav {
                            font-weight: bold;
                        }
                    }
                }

                .list__element {
                    margin-bottom: 10px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                    display: block;

                    &:hover, &.active {
                        a {
                            font-weight: bold;
                        }
                    }

                    &.active {
                        animation: pulse 0.2s;
                        border-bottom: 2px solid var(--color-black);

                    }


                    a {
                        display: block;
                        padding: 15px 0;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 1;
                        align-items: center;
                        text-transform: uppercase;
                        width: 100%;
                        color: var(--color-black);
                    }
                }
            }

        }
    }

    &__body {
        margin: 0;
        padding-bottom: 0;
        width: 100%;

        h2, p, a {
            padding: 0;
            color: var(--color-black);
            font-size: 16px;
            line-height: 22px;
            margin: 0 0 20px;
        }
        span {
            white-space: normal;
        }

        a {
            text-decoration: underline;
        }

        h2 {
            font-weight: bold;
            text-transform: uppercase;
        }

    }

    &__image {
        width: auto;
        height: auto;
        margin-bottom: 30px;

        img {
            object-fit: cover;
        }
    }

    &__articles {
        margin: 0;
        padding-bottom: 0;
        width: 100%;
        max-width: 820px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .articles__card {
            margin-bottom: 30px;
        }

        .articles__card__name {
            height: auto;
        }

        &__nav {
            justify-content: flex-end;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            width: 100%;
            max-width: 820px;
            display: flex;
        }
    }


    &__products {
        margin: 0;
        padding-bottom: 0;
        width: 100%;

        .products__card {
            margin: 0 auto 30px;
            max-width: 414px;

        }

        .products__card__name {
            height: auto;
        }

        &__nav {
            margin-top: 20px;
            justify-content: center;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            align-content: center;
            width: 100%;
            display: flex;
        }
    }


    &__block__selection {
        margin-top: 0px;
        margin-bottom: 0px;
        animation: pulse 1s;
        border-left: 4px solid var(--color-gold);
        padding-left: 15px;

        a {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            text-transform: uppercase;
            display: block;
            padding-top: 5px;
            padding-bottom: 5px;
            color: var(--color-gold);

            &:hover {
                color: var(--color-red);
            }
        }
    }
}

.smiesitieli__show, .radiatory_show {
    .pages__left-block {
        border: none;
        padding-bottom: 20px;
    }

    .pages__right-block {
        margin-top: 20px;

    }
}

.products__show, .products__docs, .products__specifications, .products__additionally {
    .pages__container {
        padding: 0;
    }
}
