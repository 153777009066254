.header {
    position: fixed;
    height: auto;
    top: 30px;
    z-index: 99;
    margin: 0 auto;
    right: 30px;

    &__logo {
        display: none;
    }

    &__container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
    }

    &__menu {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        padding: 100px 60px;
        margin: 0;
        width: 100%;
        z-index: 5;
        overflow: auto;

        &_open {
            display: block;
        }

        background-color: var(--color-red);

    }

    &__button {
        position: relative;
        display: flex;
        list-style: none;
        width: 44px;
        height: 44px;
        z-index: 999;
        .popup__btn-close {
            display: none;
        }

        &__open {
            display: block;

            .popup__btn-close {
                display: block;
                position: relative;
                left: auto;
                right: auto;
                bottom: auto;
            }

            button {
                display: none;
            }

        }
    }

    &__nav {

    }

    &__list {
        margin: 0;
        padding: 0;

        li {
            list-style: none;

        }

        a {
            text-transform: uppercase;
            display: block;
            font-stretch: normal;

            letter-spacing: normal;
            text-align: center;
            text-decoration: none;
            padding-top: 20px;
            padding-bottom: 20px;
            color: var(--color-white);
            font-size: 18px;
            line-height: 1;
            font-weight: bold;

            &:hover, &:focus, &.active {
                text-decoration: underline;
            }
        }
    }
}

body {
    &.body_scroll-hidden {
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
    }
}





