.calculations__search {
    .pages__container {
        padding-top: 10px;
    }
}

.calculations__product__wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

    .products__card {
        display: flex;
        position: relative;
        height: auto;
        width: auto;
        align-items: stretch;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 50px;

        &__info{
            margin-bottom: 50px;
            margin-top: 30px;
            .line{
                display: block;
                height: 2px;
                width: 100%;
                background: var(--color-gold);
            }
            .title__info {
                padding: 0;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: baseline;
                text-transform: uppercase;
                color: var(--color-gold);
                margin: 15px 0;

            }

            .data__info {


                span {
                    font-size: 36px;
                    font-weight: bold;
                }
            }
        }


        &__image {
            display: flex;

            a {
                display: block;
                text-decoration: none;
                color: var(--color-black);

                &:hover {
                    text-decoration: underline;

                    img {
                        filter: grayscale(0%);
                    }
                }
            }

            img {
                object-fit: cover;
                filter: grayscale(75%);
            }
        }
    }
}
