.products {
    &__title {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-bottom: 50px;

        h1 {
            margin: 0;
            padding: 0;

            font-weight: 300;
            font-size: 36px;
            line-height: 1.5;
            color: var(--color-black);
            border-bottom: 2px solid var(--color-gold);
        }
    }


    &__card {


        &__image {
            position: relative;

            a {
                display: block;
                text-decoration: none;
                color: var(--color-black);

                &:hover {
                    text-decoration: underline;

                    img {
                        filter: grayscale(0%);
                    }
                }
            }

            img {
                object-fit: cover;
                filter: grayscale(75%);
            }


        }

        &__label {
            display: block;
            position: absolute;
            background: var(--color-gold);
            color: var(--color-white);
            font-size: 14px;
            line-height: 40px;
            letter-spacing: 0.1em;
            width: 130px;
            height: 40px;
            text-align: center;
            top: 320px;
            left: -5px;
        }

        &__name {
            border-top: 3px solid #262626;
            padding-top: 15px;
            margin-top: 15px;
            display: block;
            background: var(--color-white);
            color: var(--color-black);
            font-size: 18px;
            line-height: 22px;
            font-weight: bold;
            text-transform: uppercase;
        }

        &__description {
            display: flex;
            background: var(--color-white);

            color: var(--color-black);
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }
}


