.products__show, .products__specifications, .products__docs, .products__additionally, .selections__index, .calculations__index, .calculations__search {
    .header {
        position: relative;
        top: auto;
        margin: 0 auto;
        right: auto;
        background: var(--color-red);
        height: 90px;
        &__logo {
            display: block;
            margin-left: 15px;
        }

        &__container {
            display: flex;
            position: relative;
            width: 100%;
            height: 90px;
            justify-content: space-between
        }


        &__button {
            position: relative;
            display: flex;
            list-style: none;
            width: 44px;
            height: 44px;
            z-index: 999;
            margin-right: 15px;

            .popup__btn-close {
                display: none;
            }

            button{
                background: var(--color-black-light);
            }

            &__open {
                display: block;

                .popup__btn-close {
                    display: block;
                    position: relative;
                    left: auto;
                    right: auto;
                    bottom: auto;
                }

                button {
                    display: none;
                }

            }
        }

    }








}

