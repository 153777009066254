.catalog__menu {
    position: relative;
    height: auto;
    background: var(--color-black-light);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 30px;

    &__element {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: var(--color-gold);
        border-bottom: 2px solid transparent;
        width: 100%;
        &:first-of-type {
            border-bottom: 2px solid var(--color-gold);

        }

        &:hover, &.active {
            color: var(--color-white);
        }
    }

    &__link {
        font-weight: 300;
        font-size: 24px;
        line-height: 1;
        display: flex;
        width: 100%;
        align-items: center;
        text-transform: uppercase;
        color: inherit;
        text-decoration: none;
        justify-content: center;
        padding: 30px;
    }


}


.catalog__menu.products {
    background: var(--color-red);
    height: 100px;
    .catalog__menu__element {
        height: 100px;
        border: none;

    }
}
.catalog__menu.product {
    background: transparent;
    height: 90px;
    padding: 20px 30px;
    margin: 0;
    .catalog__menu__element {
        height: 90px;
        border: none;
        color: var(--color-gold);
        margin: 0;
        padding: 0;
        border-left: 2px solid var(--color-gold);
        padding-left: 15px;
    }

    .catalog__menu__link{
        margin: 0;
        padding: 0;
        color: var(--color-gold);
        font-weight: normal;
        justify-content: flex-start
    }
}
