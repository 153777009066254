.icon__slides {
    position: relative;
    background: var(--color-red);
    padding: 30px 15px;
    border-bottom: 5px solid var(--color-gold);

    &.product {
        margin-left: 100px;
        margin-right: 100px;

        .icon__slides__wrap {
            padding-top: 0;
        }
    }

    &__title  {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;

        h1, .head__title {
            padding: 0;
            font-weight: 300;
            font-size: 24px;
            line-height: 55px;
            color: var(--color-white);
            border-bottom: 2px solid var(--color-gold);
            margin: 0 0 50px;
        }

    }

    &__wrap {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    &__image {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;

        img {
            margin: 0 auto;
        }
    }

    &__name {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding-top: 10px;

        p {
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            color: var(--color-white);
        }
    }
}
.icon__slides__wrap-dots{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    margin: 0;
    list-style: none;

    .slick-dots {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: baseline;

        li {
            text-align: center;
            margin: 0 10px;
        }

        button {
            display: inline-block;
            padding: 0;
            width: 13px;
            height: 13px;
            background-color: var(--color-white);
            border-radius: 50%;
            font-size: 0;
            line-height: 0;
            outline: none;
            border: none;
            cursor: pointer;
        }
    }

    .slick-active {
        button {
            width: 20px;
            height: 20px;
            background-color: var(--color-white);
        }
    }
}
