.footer {
    background-color: var(--color-white);
    border: none;
    padding: 60px 15px;

    &--logo {
        position: relative;
        text-align: center;
        margin-bottom: 50px;
        a {
            display: block;
        }

        svg {
            width: 122px;
            path {
                fill: var(--color-black);
            }
        }
    }

    &__menu {
        text-align: center;

        h5 {
            padding: 0;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0 0 20px;

        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 18px;
            font-weight: normal;
            line-height: 1;

            li {
                display: block;
                margin-bottom: 10px;

                a {
                    color: var(--color-black);

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &--all {
            margin-bottom: 50px;
        }

        &--products {
            margin-bottom: 50px;
        }

        &--social {
            h5 {
                text-align: center;
            }
        }
    }


    &__social {
        text-align: center;

        .social {
            flex-direction: row;
            align-items: center;

            &__link {
                path {
                    fill: var(--color-black) !important;
                }

                &:not(:last-of-type) {
                    margin: 0 8px 0 0;
                }

                &:hover {
                    path {
                        fill: var(--color-red) !important;
                    }
                }
            }
        }

    }
    &__oba {
        display: flex;
        width: 100%;
        justify-content: center;
        span {
            align-items: center;
            font-size: 12px;
            color: var(--color-black);
        }
        a {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: var(--color-black);
            flex-direction: column;
            span {
                margin-right: 10px;
                font-size: 12px;
                color: var(--color-black);
            }
            svg{
                font-size: 12px;
                width: 100px;
            }
        }
    }
}
